import React, { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { stack as Menu } from 'react-burger-menu';
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/User";
import { SettingsContext } from "../../contexts/Settings";

//axios and base url
import axios from "axios";
import { BASE_URL, SAAS_APPLICATION } from "../../BaseUrl";

//functions
import {
    _t,
    getCookie,
    deleteCookie,
    getSystemSettings,
} from "../../functions/Functions";

export default props => {
    const { t, i18n } = useTranslation();
    //auth user
    const { authUserInfo } = useContext(UserContext);

    //getting context values here
    let { navLanguageList, navCurrencyList, generalSettings, showManageStock, paypal_client_id } =
      useContext(SettingsContext);

    const [deliverymenShow, setdeliverymenShow] = useState(false);

    // deliveryman menu update
    const deliveryMenu = () => {

        const url = BASE_URL + `/settings/deliverymen-menu-info`;
        axios.get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
        }).then((res) => {
            if (res.data.length == 0 || res.data[0].value == 1) {
                setdeliverymenShow(true);
            } else {
                setdeliverymenShow(false);
            }
        });

    }

    //logout
    const handleLogout = () => {
        deleteCookie();
    };

    return (
        <Menu>
            <a className="menu-item" href="#home">
                {_t(t("home"))}
            </a>
            <a className="menu-item" href="#popular">
                {_t(t("popular"))}
            </a>
            <a className="menu-item" href="#special">
                {_t(t("Special"))}
            </a>
            <a className="menu-item" href="#language">
                {_t(t("Language"))}
            </a>
            {authUserInfo &&
                authUserInfo.details &&
                authUserInfo.details.user_type === "customer" && (
                    <NavLink className="menu-item" to="/profile">{_t(t("Profile"))}</NavLink>
                )}

            {deliverymenShow == true && authUserInfo && !authUserInfo.details && (
                <NavLink className="menu-item" to="/delivery-man-registration">
                    {_t(t("deliveryman"))}
                </NavLink>
            )}
            {getCookie() === undefined ? (
                <NavLink className="menu-item" to="/login">
                    {_t(t("Login"))}
                </NavLink>
            ) : (
                <>
                    {authUserInfo &&
                        authUserInfo.details &&
                        authUserInfo.details.user_type !== "customer" ? (
                        <NavLink className="menu-item" to="/dashboard">
                            {_t(t("Dashboard"))}
                        </NavLink>
                    ) : (
                        <NavLink className="bm-item menu-item" to="#" onClick={handleLogout}>
                            {_t(t("Logout"))}
                        </NavLink>
                    )}
                </>
            )}
            <div className="order-content">
                <span className="span-1">{_t(t("Need Help"))}?</span>{" "}
                <span className="span-2">
                    {getSystemSettings(generalSettings, "phnNo")}
                </span>
            </div>
        </Menu>

    );
};
